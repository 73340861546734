export const statusStyleArray = [
  {
    status: "INITIATED",
    color: "#096dd9",
    backgroundColor: "#e6f7ff",
  },
  {
    status: "completed",
    color: "#389e0d",
    backgroundColor: "#f6ffed",
  },
  {
    status: "Not Started",
    backgroundColor: "#d46b08",
    color: "#fff7e6",
  },
  {
    status: "PAID",
    color: "#fff",
    backgroundColor: "rgb(56, 158, 13)",
  },
  {
    status: "delivered",
    color: "#389e0d",
    backgroundColor: "#f6ffed",
  },
  {
    status: "CREATED",
    backgroundColor: "#c41d7f",
    color: "#fff0f6",
  },
];
