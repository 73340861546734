import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  CircularProgress,
  Pagination,
  Button,
  styled,
} from "@mui/material";
import tabs from "./config";
import { getApiData } from "../../utils/APIHelper";
import { useNavigate } from "react-router-dom";
import PackageDetails from "./components/PackageDetails";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const StyledHeading = styled("h2")({
  color: "#0D0D0E",
  fontFamily: "Inter Tight",
  fontSize: "24px",
  fontWeight: "500",
});

const StyledButton = styled(Button)(({ theme }) => ({
  background: "#4D6BF6",
  color: "#ffffff",
  boxShadow: "none",
  border: "none",
  whiteSpace: "nowrap",
  fontSize: "16px",
  fontWeight: "800",
  borderRadius: "10px",
  padding: ".5vh .7vw",
  letterSpacing: "0",
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  padding: "0 2vw",
}));

const StyledTab = styled(Tab)(({ theme, selected }) => ({
  color: selected ? "#fff !important" : "#949DAA",
  backgroundColor: selected ? "#0d0d0d" : "#fff",
  border: "1px solid #d7d8d9",
  borderRadius: "10px",
  minHeight: "40px !important",
  fontWeight: 500,
  textTransform: "none",
  marginRight: "10px",
  padding: "4px 16px !important",
  fontFamily: "Inter Tight",
  "&:hover": {
    backgroundColor: selected ? "#0d0d0d" : "",
    color: !selected && "#000",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent !important",
  },
}));

export default function Packages() {
  const { userData } = useSelector((state) => state.auth);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(0);
    setDetails([]);
  };

  const handlePageChange = (event, value) => {
    setPage(value - 1);
  };

  const fetchData = async () => {
    setLoading(true);
    const selectedType = tabs[selectedTab]?.type;
    try {
      const response = await getApiData(
        `api/v2/package/owner/${userData?.contractor_data?.id}/list?page=${page}&size=10&status=${selectedType}`,
        "GET",
        {},
        {},
        true
      );
      const newDetails = response?.content || [];
      setDetails(newDetails);
      setTotalPages(response?.totalPages || 0);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (packageId) => {
    try {
      const response = await getApiData(
        `api/v2/package/${packageId}`,
        "DELETE",
        {},
        {},
        true
      );
      toast.success("Package Deleted Successfully");
      fetchData();
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClone = async (packageId) => {
    try {
      const response = await getApiData(
        `api/v2/package/${packageId}/clone`,
        "POST",
        {},
        {},
        true
      );
      toast.success("Package Cloned Successfully");
      fetchData();
      setSelectedTab(0);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.contractor_data?.id) {
      fetchData();
    }
  }, [userData?.contractor_data?.id, selectedTab, page]);

  return (
    <div style={{ height: "100%" }}>
      <Box
        sx={{
          typography: "body1",
          backgroundColor: "#fff",
          height: "100%",
          borderRadius: "6px",
          fontFamily: "Inter Tight",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4vh 2vw 2vh 2vw",
            background:
              "linear-gradient(180deg, rgba(77, 107, 246, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
          }}
        >
          <StyledHeading>Packages</StyledHeading>
          <StyledButton
            variant="contained"
            onClick={() => {
              navigate("/create-package", {
                state: {
                  isCreateProposal: true,
                },
              });
            }}
          >
            <strong>Create Package</strong>
          </StyledButton>
        </div>
        <StyledTabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Styled Tabs"
          sx={{
            "& .MuiTabs-indicator": {
              background: "none",
            },
          }}
        >
          {tabs?.map((tab, index) => (
            <StyledTab
              key={tab.id}
              label={tab.title}
              selected={selectedTab === index}
            />
          ))}
        </StyledTabs>
        <Box sx={{ padding: "0 2vw" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <CircularProgress />
            </Box>
          ) : details?.length > 0 ? (
            <PackageDetails
              details={details}
              handleDelete={handleDelete}
              handleSubmitClone={handleClone}
              selectedTab={selectedTab}
            />
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", marginTop: 4 }}
            >
              No data available
            </Typography>
          )}
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handlePageChange}
            color="primary"
            sx={{ marginTop: 3, display: "flex", justifyContent: "center" }}
          />
        </Box>
      </Box>
    </div>
  );
}
