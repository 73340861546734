import { Grid, styled, Tab, Tabs, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/reducers/auth/actions";
import About from "../../components/About";
import EditProfile from "../EditProfile";
import Billing from "../Billing";
import ChangePassword from "../ChangePassword";
import PhoneVerificationModal from "../../components/PhoneVerificationModal";
import TwoFectorModal from "../../components/TwoFectorModal";
import NotificationSettings from "../NotificationSettings";
import Security from "../../components/Security";
import useStyles from "./styles";

const StyledHeading = styled("h2")({
  color: "#0D0D0E",
  fontFamily: "Inter Tight",
  fontSize: "24px",
  fontWeight: "500",
});

const StyledTab = styled(Tab)(({ theme, selected }) => ({
  color: selected ? "#fff !important" : "#949DAA",
  backgroundColor: selected ? "#0d0d0d" : "#fff",
  border: "1px solid #d7d8d9",
  borderRadius: "10px",
  minHeight: "40px !important",
  fontWeight: 500,
  textTransform: "none",
  marginRight: "10px",
  padding: "4px 16px !important",
  fontFamily: "Inter Tight",
  "&:hover": {
    backgroundColor: selected ? "#0d0d0d" : "",
    color: !selected && "#000",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent !important",
  },
}));

export default function AccountSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setAccountTab } = authActions;
  const { userData, accountTab } = useSelector((state) => state.auth);
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [show2FModal, setShow2FModal] = useState(false);

  useEffect(() => {
    setTabValue(accountTab);
  }, [accountTab]);

  return (
    <>
      <Box
        sx={{
          typography: "body1",
          backgroundColor: "#fff",
          height: "100%",
          borderRadius: "6px",
          fontFamily: "Inter Tight",
        }}
      >
        <div
          style={{
            padding: "4vh 2vw 2vh 2vw",
            background:
              "linear-gradient(180deg, rgba(77, 107, 246, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
          }}
        >
          <StyledHeading>Account Settings</StyledHeading>
        </div>

        <Grid item sx={{ padding: "0 2vw" }}>
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={(v, b) => {
                setTabValue(b);
                dispatch(setAccountTab(b));
              }}
              variant="scrollable"
              sx={{
                "& .MuiTabs-indicator": {
                  background: "none",
                },
              }}
            >
              <StyledTab label="Profile" selected={tabValue === 0} />
              <StyledTab label="Billing" selected={tabValue === 1} />
              <StyledTab label="Notifications" selected={tabValue === 2} />
              {userData?.social_connection === "google" ||
              userData?.social_connection === "fb" ||
              userData?.social_connection === "apple" ? null : (
                <StyledTab label="Change Password" selected={tabValue === 3} />
              )}
              <StyledTab label="Security" selected={tabValue === 4} />
            </Tabs>
          </Grid>
          {tabValue === 0 ? <EditProfile /> : null}
          {tabValue === 1 ? <Billing /> : null}
          {tabValue === 2 ? <NotificationSettings /> : null}
          {tabValue === 3 ? (
            userData?.social_connection === "google" ||
            userData?.social_connection === "fb" ||
            userData?.social_connection === "apple" ? (
              <Security />
            ) : (
              <ChangePassword />
            )
          ) : null}
          {tabValue === 4 ? <Security /> : null}
        </Grid>
      </Box>

      {/* <About /> */}
      <PhoneVerificationModal
        visible={showModal}
        handleClose={() => setShowModal(false)}
      />
      <TwoFectorModal
        visible={show2FModal}
        handleClose={() => setShow2FModal(false)}
      />
    </>
  );
}
