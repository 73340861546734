import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  styled,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { CopyAllOutlined, DeleteOutline } from "@mui/icons-material";
import { statusStyleArray } from "../config";
import { formattedAmountWithoutAed } from "../../../utils/CommonFunction";
import { useNavigate } from "react-router-dom";
import ConfirmModel from "../../../components/ConfirmModel";

export const getStatusDisplayText = (text) => {
  if (typeof text !== "string") return text;

  const displayText = text
    .replace(/-/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return displayText;
};

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: "none",
}));

const ActiveStyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  display: "flex",
  alignItems: "center",
  gap: "7px",
  marginTop: "13px",
}));

export default function PackageDetails({
  details,
  handleDelete,
  handleSubmitClone,
  selectedTab,
}) {
  const navigate = useNavigate();
  const handleChange = (item) => {
    navigate("/edit-package", {
      state: { item, status: "submitted" },
    });
  };

  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [cloneData, setCloneData] = useState(null);
  const [cloneName, setCloneName] = useState("");

  const handleClone = (item) => {
    setCloneData(item);
    setCloneName(`Copy - ${item.name}`);
    setCloneDialogOpen(true);
  };

  const handleCloneSave = async () => {
    if (cloneData) {
      await handleSubmitClone(cloneData.id);
    }
    setCloneDialogOpen(false);
  };
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflow: "none !important",
          boxShadow: "none",
          maxHeight: "80vh",
        }}
      >
        <Table
          sx={{
            "& thead": {
              "& th": {
                color: "#949DAA",
                fontWeight: "500",
              },
            },

            "& tbody": {
              "& tr:hover": {
                backgroundColor: "#f7f7f7",
              },

              "& tr": {
                borderBottom: "none",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>S.No.</StyledTableCell>
              <StyledTableCell sx={{ width: "25%", fontWeight: "bold" }}>
                Package Name
              </StyledTableCell>
              <StyledTableCell>Project Type</StyledTableCell>
              <StyledTableCell>Budget Type</StyledTableCell>
              <StyledTableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                Cost (AED)
              </StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              {selectedTab === 3 && <StyledTableCell>Active</StyledTableCell>}
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.map((item, idx) => (
              <TableRow key={item.id}>
                <StyledTableCell>{idx + 1}.</StyledTableCell>
                <StyledTableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ flexWrap: "wrap", flex: 1 }}
                  >
                    <Box
                      component="img"
                      src={
                        item?.primaryImage
                          ? JSON.parse(item?.primaryImage)?.image
                          : "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"
                      }
                      alt="Package Icon"
                      sx={{
                        width: 45,
                        height: 45,
                        borderRadius: "8px",
                        border: "2px solid white",
                        boxShadow: "0px 4px 2.67px 0px #00000026",
                        marginRight: 1,
                        flexShrink: 0,
                      }}
                    />
                    <Typography
                      sx={{
                        flex: 1,
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "normal",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>{item.projectType}</StyledTableCell>
                <StyledTableCell>
                  <Typography
                    style={{
                      borderRadius: "34px",
                      padding: "4px 8px",
                      fontSize: "14px",
                      minWidth: "7ch",
                      width: "fit-content",
                      backgroundColor:
                        statusStyleArray.find(
                          (style) => style.status === item?.budgetType
                        )?.backgroundColor || "transparent",
                      color:
                        statusStyleArray.find(
                          (style) => style.status === item?.budgetType
                        )?.color || "black",
                      border: "none",
                    }}
                  >
                    {getStatusDisplayText(item?.budgetType)}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "right" }}>
                  <strong>{formattedAmountWithoutAed(item?.cost)}</strong>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    style={{
                      borderRadius: "34px",
                      padding: "4px 8px",
                      fontSize: "14px",
                      minWidth: "7ch",
                      width: "fit-content",
                      backgroundColor:
                        statusStyleArray.find(
                          (style) => style.status === item?.status
                        )?.backgroundColor || "transparent",
                      color:
                        statusStyleArray.find(
                          (style) => style.status === item?.status
                        )?.color || "black",
                      border: "none",
                      textAlign: "center",
                    }}
                  >
                    {getStatusDisplayText(
                      item?.status === "CREATED" ? "DRAFT" : item?.status
                    )}
                  </Typography>
                </StyledTableCell>
                {selectedTab === 3 && (
                  <ActiveStyledTableCell>
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: item?.isActive ? "#4BB34B" : "#E54746",
                        border: "3px solid #e5e5e5",
                      }}
                    ></div>
                    <div>{item?.isActive ? "ON" : "OFF"}</div>
                  </ActiveStyledTableCell>
                )}

                <StyledTableCell>
                  {item?.status === "approved" ? (
                    <IconButton
                      size="small"
                      title="View"
                      onClick={() => handleChange(item)}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <>
                      <IconButton
                        size="small"
                        title="Edit Package"
                        onClick={() => handleChange(item)}
                      >
                        <EditIcon fontSize="20" />
                      </IconButton>
                      <IconButton
                        size="small"
                        title="Clone Package"
                        onClick={() => handleClone(item)}
                      >
                        <CopyAllOutlined fontSize="20" />
                      </IconButton>
                      <IconButton
                        size="small"
                        title="Delete Package"
                        onClick={() => handleDelete(item.id)}
                      >
                        <DeleteOutline
                          fontSize="20"
                          style={{ color: "#E54746", marginBottom: "3px" }}
                        />
                      </IconButton>
                    </>
                  )}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmModel
        visible={cloneDialogOpen}
        handleClose={() => setCloneDialogOpen(false)}
        confirmation={() => {
          handleCloneSave();
        }}
        message={
          <>
           <p>Are you sure you want to clone this package?</p>        
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ flexWrap: "wrap", flex: 1, marginTop:"1vh" }}
            >
              <Box
                component="img"
                src={
                  cloneData?.primaryImage
                    ? JSON.parse(cloneData?.primaryImage)?.image
                    : "https://renohome.blob.core.windows.net/reno-cms/e56d3d53-e335-425f-990e-16e6b2bbee1b"
                }
                alt="Package Icon"
                sx={{
                  width: 45,
                  height: 45,
                  borderRadius: "8px",
                  border: "2px solid white",
                  boxShadow: "0px 4px 2.67px 0px #00000026",
                  marginRight: 1,
                  flexShrink: 0,
                }}
              />
              <Typography
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {cloneData?.name}
              </Typography>
            </Box>
          </>
        }
      />
    </>
  );
}
