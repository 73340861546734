import {
  Grid,
  Typography,
  IconButton,
  CircularProgress,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import { getApiData, postFormData } from "../../utils/APIHelper";
import { toast } from "react-toastify";
import UploadIcon from "@mui/icons-material/Upload";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { Setting } from "../../utils/Setting";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineAccessTime } from "react-icons/md";
import { RiFolderUploadLine } from "react-icons/ri";
import { isArray, isEmpty } from "lodash";

const StyledHeading = styled("h2")({
  color: "#0D0D0E",
  fontFamily: "Inter Tight",
  fontSize: "24px",
  fontWeight: "500",
});

export default function DesignerDocuments() {
  const classes = useStyles();
  const { userData } = useSelector((state) => state.auth);

  const [documentList, setDocumentList] = useState([]);
  const [isCalled, setIsCalled] = useState(false);
  const [selectedDocId, setSelectedDocId] = useState(0);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData && userData?.id) {
      getDocumentsList();
    }
  }, [userData]);

  useEffect(() => {
    if (isCalled && userData?.id) {
      getDocumentsList();
    }
  }, [isCalled, userData]);

  async function getDocumentsList() {
    setLoading(true);
    try {
      const designerId = userData?.id;
      if (!designerId) {
        toast.error("Designer ID is missing");
        setLoading(false);
        return;
      }

      const response = await getApiData(
        `api/user/designer/${designerId}/kyb-documents`,
        "GET",
        {}
      );
      if (response?.success) {
        const convertedData = convertDocumentData(response?.documentLists);
        setDocumentList(convertedData);
        setLoading(false);
        setIsCalled(false);
      }
    } catch (error) {
      toast.error("ERROR", error);
    }
  }

  const convertDocumentData = (apiData) => {
    const documentLists = apiData;
    const groupedData = new Map();

    documentLists.forEach((doc) => {
      const type = doc.type || "Unknown Type";
      const sub_data = doc.sub_type || "Unknown Subtype";

      if (!groupedData.has(type)) {
        groupedData.set(type, []);
      }

      groupedData.get(type).push({
        id: doc.id,
        name: sub_data,
        doc_type_id: doc.doc_type_id,
        ...doc,
      });
    });

    const documentData = Array.from(groupedData, ([type, sub_data]) => ({
      type,
      sub_data,
    }));

    return documentData;
  };

  const countStatuses = (sub_data) => {
    const statusCounts = {
      verified: 0,
      submitted: 0,
      pending: 0,
      rejected: 0,
    };

    sub_data.forEach((doc) => {
      if (doc?.is_verified) {
        statusCounts.verified += 1;
      } else if (!doc?.is_verified && !doc?.url) {
        statusCounts.pending += 1;
      } else if (!doc?.is_verified && doc?.url && doc?.status !== "REJECTED") {
        statusCounts.submitted += 1;
      } else {
        statusCounts.rejected += 1;
      }
    });

    return statusCounts;
  };

  const handleUpload = (id) => {
    setSelectedDocId(id);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    handleFileUpload(event.target.files[0]);
  };

  async function handleFileUpload(file) {
    if (!file) {
      toast.error("No file selected");
      return;
    }
    const designerId = userData?.id;
    if (!designerId) {
      toast.error("Designer ID is missing");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("client_document", file);
    formData.append("doc_type_id", selectedDocId);
    formData.append("assign_type", "designer");
    formData.append("assign_id", designerId.toString());

    try {
      const response = await postFormData(
        Setting.endpoints.uploadDocument,
        formData
      );
      if (response.success) {
        toast.success("File uploaded successfully");
        setSelectedDocId(0);
        setIsCalled(true);
      } else {
        toast.error("File upload failed");
      }
    } catch (error) {
      toast.error("An error occurred during file upload");
    }
  }

  async function handleDelete(id) {
    console.log("Delete document with ID:", id);
    const designerId = userData?.id;
    if (!designerId) {
      toast.error("Designer ID is missing");
      setLoading(false);
      return;
    }
    let body = {
      doc_type_id: id,
      assign_type: "designer",
      assign_id: designerId.toString(),
    };
    try {
      const response = await getApiData(
        Setting.endpoints.deleteDocument,
        "POST",
        body
      );
      console.log("res", response);
      if (response.status) {
        toast.success(response?.message);
        setIsCalled(true);
      }
    } catch (error) {
      toast.error("An error occurred during file upload");
    }
  }

  const renderVerificationStatus = (doc) => {
    if (doc?.is_verified) {
      return (
        <>
          <IoMdCheckmark className={classes.verified_btn} />
          Verified
        </>
      );
    } else if (!doc?.is_verified && !doc?.url) {
      return (
        <>
          <MdOutlineAccessTime className={classes.pending_btn} />
          Pending
        </>
      );
    } else if (!doc?.is_verified && doc?.url && doc?.status !== "REJECTED") {
      return (
        <>
          <RiFolderUploadLine className={classes.submitted_btn} />
          Submitted
        </>
      );
    } else {
      return (
        <>
          <RxCross2 className={classes.not_verified_btn} />
          Rejected
        </>
      );
    }
  };

  const renderStatusCounts = (status) => {
    return (
      <div className={classes.status_wrapper}>
        <div className={classes.text}>
          <p>Verified:</p>
          <p className={classes.verified_text}>{status?.verified}</p>
        </div>
        <div className={classes.text}>
          <p>Pending:</p>
          <p className={classes.pending_text}>{status?.pending}</p>
        </div>
        <div className={classes.text}>
          <p>Submitted:</p>
          <p className={classes.submitted_text}>{status?.submitted}</p>
        </div>
        <div className={classes.text}>
          <p>Rejected:</p>
          <p className={classes.not_verified_text}>{status?.rejected}</p>
        </div>
      </div>
    );
  };

  return (
    <Grid spacing={2}>
      <div
        style={{
          padding: "4vh 2vw 2vh 2vw",
          background:
            "linear-gradient(180deg, rgba(77, 107, 246, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
        }}
      >
        <StyledHeading>Designer Documents</StyledHeading>
      </div>
      {loading ? (
        <Grid item xs={12} className={classes.loader}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          item
          container
          borderRadius={"8px"}
          style={{ padding: "0 1.5vw" }}
        >
          {isArray(documentList) && !isEmpty(documentList) ? (
            <>
              {documentList?.map((item, index) => {
                const statusCounts = countStatuses(item?.sub_data);
                return (
                  <Grid item xs={12} key={index} className={classes.section}>
                    <Typography variant="h6" className={classes.subHeader}>
                      <p>{item?.type}</p>
                      <p>{renderStatusCounts(statusCounts)}</p>
                    </Typography>
                    {item?.sub_data.map((doc, idx) => (
                      <div
                        key={doc?.doc_type_id}
                        className={classes.documentRow}
                      >
                        <div className={classes.row} style={{ flex: ".07" }}>
                          <Typography>{idx + 1}</Typography>
                        </div>
                        <div className={classes.row}>
                          <Typography className={classes.heading}>
                            {doc?.sub_type}
                            <span className={classes.mandatory}>
                              {doc?.is_mandatory && "*"}
                            </span>
                          </Typography>
                        </div>

                        <div className={classes.row} style={{ flex: "1.2" }}>
                          {doc?.url ? (
                            <a
                              href={doc?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={classes.file_url}
                            >
                              <FileIcon />
                              {doc?.sub_type} File
                            </a>
                          ) : (
                            <Typography>No file Uploaded</Typography>
                          )}
                        </div>

                        <div className={classes.row}>
                          <div className={classes.status}>
                            {renderVerificationStatus(doc)}
                          </div>
                        </div>

                        <div className={classes.action}>
                          <div>
                            <input
                              type="file"
                              accept="application/pdf"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            <IconButton
                              component="label"
                              onClick={() => {
                                handleUpload(doc?.doc_type_id);
                                document
                                  .querySelector('input[type="file"]')
                                  .click();
                              }}
                              sx={{ mb: 1 }}
                              disabled={doc?.url && doc?.status !== "REJECTED"}
                            >
                              <UploadIcon />
                              <span className={classes.btn}>Upload</span>
                            </IconButton>
                          </div>

                          <div>
                            <IconButton
                              component="label"
                              onClick={() => handleDelete(doc?.doc_type_id)}
                              disabled={!doc?.url || doc?.status === "VERIFIED"}
                              sx={{ mb: 1 }}
                            >
                              <DeleteIcon />
                              <span className={classes.btn}>Delete</span>
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Grid>
                );
              })}
            </>
          ) : (
            <div style={{ width: "100%", textAlign: "center" }}>
              <h2>No Documents Found</h2>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
}
