export const statusStyleArray = [
  {
    status: "STANDARD",
    color: "#d46b08",
    backgroundColor: "#fff7e6",
  },
  {
    status: "ongoing",
    color: "#096dd9",
    backgroundColor: "#e6f7ff",
  },
  {
    status: "awaiting-contractor-confirmation",
    color: "#c41d7f",
    backgroundColor: "#fff0f6",
  },
  {
    status: "delivered",
    color: "#389e0d",
    backgroundColor: "#f6ffed",
  },
  {
    status: "Requested",
    backgroundColor: "#096dd9",
    color: "#e6f7ff",
  },
  {
    status: "awaiting-approval",
    color: "#d4b106",
    backgroundColor: "#feffe6",
  },
  // {
  //   status: "awaiting-contractor-confirmation",
  //   backgroundColor: "#d46b08",
  //   color: "#fff7e6",
  // },
  {
    status: "completed",
    color: "#fff",
    backgroundColor: "rgb(56, 158, 13)",
  },
  {
    status: "in_warranty",
    backgroundColor: "#c41d7f",
    color: "#fff0f6",
  },
];
