import React from "react";
import {
  Box,
  Paper,
  Typography,
  LinearProgress,
  TableCell,
  styled,
} from "@mui/material";
import { formattedAmount } from "../../../utils/CommonFunction";

const StyledTypography = styled(Typography)(({ theme }) => ({
  borderBottom: "none",
  display: "flex",
  alignItems: "center",
  fontSize: "18px",
  color: "#0D0D0E",
  fontWeight: 500,
  gap: "7px",
}));

const ProjectStats = ({ summary }) => {
  const stats = [
    {
      label: "Total Amount",
      value: formattedAmount(summary.totalProjectCost),
    },
    {
      label: "Total Paid Amount",
      value: formattedAmount(summary.totalPaidAmount),
    },
    {
      label: "Total Due Amount",
      value: formattedAmount(summary.totalDueAmount),
    },
    {
      label: "Total Not Due Amount",
      value: formattedAmount(summary.totalNotDueAmount),
    },
  ];

  const progressColors = {
    paid: "#4BB34B",
    due: "#C3C6D6",
    notDue: "#FF971C",
  };

  const calculateSegmentWidth = (value) => {
    if (summary.totalProjectCost <= 0 || !value) return 0;
    return (value / summary.totalProjectCost) * 100;
  };

  const paidWidth = calculateSegmentWidth(summary.totalPaidAmount);
  const dueWidth = calculateSegmentWidth(summary.totalNotDueAmount);
  const notDueWidth = calculateSegmentWidth(summary.totalDueAmount);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      width="100%"
      padding="0 2vw"
    >
      <Box width="100%">
        <Paper
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            boxShadow: "none",
            borderRadius: 0,
            padding: "1vh .5vw",
          }}
        >
          <div style={{ paddingRight: "2vw" }}>
            <Typography
              sx={{
                mb: 1,
                letterSpacing: 0,
                fontSize: "14px",
                color: "#949DAA",
                fontWeight: "500",
              }}
            >
              Total Project Cost
            </Typography>
            <Typography
              sx={{
                fontSize: "24px",
                color: "#0D0D0E",
                fontWeight: 500,
              }}
            >
              {formattedAmount(summary.totalProjectCost) || "---"}
            </Typography>
          </div>
          <div style={{ borderLeft: "1px solid #D7D8D9" }}>
            <div
              style={{
                width: "100%",
                padding: "0 2vw",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "70px",
              }}
            >
              <div>
                <Typography
                  sx={{
                    mb: 1,
                    letterSpacing: 0,
                    fontSize: "14px",
                    color: "#949DAA",
                    fontWeight: "500",
                  }}
                >
                  Paid
                </Typography>
                <StyledTypography>
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      backgroundColor: progressColors.paid,
                    }}
                  ></div>
                  {formattedAmount(summary.totalPaidAmount) || "---"}
                </StyledTypography>
              </div>
              <div>
                <Typography
                  sx={{
                    mb: 1,
                    letterSpacing: 0,
                    fontSize: "14px",
                    color: "#949DAA",
                    fontWeight: "500",
                  }}
                >
                  Due
                </Typography>
                <StyledTypography>
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      backgroundColor: progressColors.notDue,
                    }}
                  ></div>
                  {formattedAmount(summary.totalDueAmount) || "---"}
                </StyledTypography>
              </div>
              <div>
                <Typography
                  sx={{
                    mb: 1,
                    letterSpacing: 0,
                    fontSize: "14px",
                    color: "#949DAA",
                    fontWeight: "500",
                  }}
                >
                  Not Due
                </Typography>
                <StyledTypography>
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%",
                      backgroundColor: progressColors.due,
                    }}
                  ></div>
                  {formattedAmount(summary.totalNotDueAmount) || "---"}
                </StyledTypography>
              </div>
            </div>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                height: 10,
                borderRadius: 5,
                backgroundColor: "transparent",
                overflow: "hidden",
                mt: 1,
                marginLeft: "2vw",
              }}
            >
              <Box
                sx={{
                  width: `${paidWidth}%`,
                  backgroundColor: progressColors.paid,
                  borderRadius: "5px",
                }}
              />
              <Box
                sx={{
                  width: `${notDueWidth}%`,
                  backgroundColor: progressColors.notDue,
                  borderRadius: "5px",
                }}
              />
              <Box
                sx={{
                  width: `${dueWidth}%`,
                  backgroundColor: progressColors.due,
                  borderRadius: "5px",
                }}
              />
            </Box>
          </div>
          {/* <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            mt={1}
          >
            <Typography sx={{ fontSize: "14px", color: progressColors.paid }}>
              Paid: {formattedAmount(summary.totalPaidAmount) || "---"}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: progressColors.due }}>
              Due: {formattedAmount(summary.totalDueAmount) || "---"}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: progressColors.notDue }}>
              Not Due: {formattedAmount(summary.totalNotDueAmount) || "---"}
            </Typography>
          </Box> */}
        </Paper>
      </Box>
    </Box>
  );
};

export default ProjectStats;
