import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
  IconButton,
  Collapse,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { formattedAmountWithoutAed } from "../../../utils/CommonFunction";
import { ReactComponent as ProjectActiveIcon } from "../../../assets/svg/ProjectActiveIcon.svg";
import moment from "moment";
import { statusStyleArray } from "../config";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/ArrowIcon.svg";

export const getStatusDisplayText = (text) => {
  if (typeof text !== "string") return text;
  console.log("test", text);
  const displayText = text
    .replace(/-/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return displayText;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  padding: "1vh 1vw !important",
}));

const StyledTableCellWithoutPadding = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  padding: "0 !important",
}));

export default function BalanceDetails({ details }) {
  const [expandedRows, setExpandedRows] = useState({});

  const handleExpandClick = (id) => {
    setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "100%",
        overflow: "auto !important",
        boxShadow: "none",
        maxHeight: "77vh",
      }}
    >
      <Table
        sx={{
          "& thead": {
            "& th": {
              color: "#949DAA",
              fontWeight: "500",
            },
          },
          "& tbody": {
            "& tr:hover": {
              backgroundColor: "#f7f7f7",
            },
            "& tr": {
              borderBottom: "none",
            },
          },
        }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Project Name</StyledTableCell>

            <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
              <strong>Total Project Value (AED)</strong>
            </StyledTableCell>

            <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
              <strong>Total Paid Amount (AED)</strong>
            </StyledTableCell>

            <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
              <strong>Total Due Amount (AED)</strong>
            </StyledTableCell>

            <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
              <strong>Total Not Due Amount (AED)</strong>
            </StyledTableCell>

            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {details?.map((item) => (
            <React.Fragment key={item.id}>
              <TableRow>
                <StyledTableCell>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "7px",
                    }}
                  >
                    <ProjectActiveIcon />
                    {item?.name}
                  </Box>
                </StyledTableCell>
                <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                  <strong>{formattedAmountWithoutAed(item?.totalCost)}</strong>
                </StyledTableCell>
                <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                  <strong>
                    {formattedAmountWithoutAed(item?.totalPaidAmount)}
                  </strong>
                </StyledTableCell>
                <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                  <strong>
                    {formattedAmountWithoutAed(item?.totalDueAmount)}
                  </strong>
                </StyledTableCell>
                <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                  <strong>
                    {formattedAmountWithoutAed(item?.totalNotDueAmount)}
                  </strong>
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: "right" }}>
                  <IconButton
                    onClick={() => handleExpandClick(item.id)}
                    size="small"
                  >
                    {expandedRows[item.id] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </StyledTableCell>
              </TableRow>

              {expandedRows[item.id] && (
                <TableRow>
                  <StyledTableCellWithoutPadding
                    colSpan={6}
                    sx={{
                      paddingBottom: 0,
                      paddingTop: 0,
                      borderBottom: "none",
                      paddingLeft: "1.5rem !important",
                    }}
                  >
                    <Collapse
                      in={expandedRows[item.id] || false}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <ArrowIcon />
                        <Table
                          size="small"
                          sx={{ width: "100%", marginTop: "1.5vh" }}
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>
                                Payment Group Name
                              </StyledTableCell>
                              <StyledTableCell>Status</StyledTableCell>
                              <StyledTableCell>Due On</StyledTableCell>
                              <StyledTableCell>Settled On</StyledTableCell>
                              <StyledTableCell>Mode</StyledTableCell>
                              <StyledTableCell sx={{ textAlign: "right" }}>
                                Payment Group Amount (AED)
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {item.payouts?.map((payout) => (
                              <TableRow key={payout.groupName}>
                                <StyledTableCell>
                                  {payout.groupName}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {payout?.status !== "CREATED" && (
                                    <Typography
                                      style={{
                                        borderRadius: "34px",
                                        padding: "4px 8px",
                                        fontSize: "14px",
                                        minWidth: "7ch",
                                        width: "fit-content",
                                        backgroundColor:
                                          statusStyleArray.find(
                                            (style) =>
                                              style.status === payout?.status
                                          )?.backgroundColor || "transparent",
                                        color:
                                          statusStyleArray.find(
                                            (style) =>
                                              style.status === payout?.status
                                          )?.color || "black",
                                        border: "none",
                                        textAlign: "center",
                                      }}
                                    >
                                      {getStatusDisplayText(payout?.status)}
                                    </Typography>
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {payout?.dueDate &&
                                    moment(payout?.dueDate).format(
                                      "MMM DD, YYYY"
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {payout?.releaseDate &&
                                    moment(payout?.releaseDate).format(
                                      "MMM DD, YYYY"
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {payout.settlementType || "-"}
                                </StyledTableCell>
                                <StyledTableCell sx={{ textAlign: "right" }}>
                                  {formattedAmountWithoutAed(payout.amount)}
                                </StyledTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </StyledTableCellWithoutPadding>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
