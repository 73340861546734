import React, { useState, useEffect } from "react";
import "./index.css";
import { getApiData } from "../../utils/APIHelper";
import { useSelector } from "react-redux";
import { Typography, CircularProgress, Stack, styled } from "@mui/material";
import { Box } from "@mui/material";
import BalanceDetails from "./components/BalanceDetails";
import ProjectStats from "./components/ProjectStats";

const StyledHeading = styled("h2")({
  color: "#0D0D0E",
  fontFamily: "Inter Tight",
  fontSize: "24px",
  fontWeight: "500",
});

export default function ProjectBalance() {
  const { userData } = useSelector((state) => state.auth);
  const [projectDetails, setProjectDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});

  useEffect(() => {
    if (userData?.contractor_data?.id) {
      fetchData();
    }
  }, [userData?.contractor_data?.id]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getApiData(
        `api/v2/contractors/${userData.contractor_data.id}/balanceSheet`,
        "GET",
        {},
        {},
        true
      );
      setProjectDetails(response?.projectBalanceSheet || []);
    } catch (error) {
      console.error("Error fetching payment details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData?.contractor_data?.id) {
      fetchSummary();
    }
  }, [userData?.contractor_data?.id]);

  const fetchSummary = async () => {
    setLoading(true);
    try {
      let filters = `contractorId:=:${userData.contractor_data.id}&sortBy=dueDate&sortOrder=ASC`;

      const response = await getApiData(
        `api/v2/dashboard/paymentOverview/summary?object=payouts&filters=${filters}`,
        "GET",
        {},
        {},
        true
      );
      setSummary(response || {});
    } catch (error) {
      console.error("Error fetching payment details:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      flex={1}
      sx={{
        fontFamily: "Inter Tight",
      }}
    >
      <div style={{ background: "#fff" }}>
        <div
          style={{
            padding: "4vh 2vw 2vh 2vw",
            background:
              "linear-gradient(180deg, rgba(77, 107, 246, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%)",
          }}
        >
          <StyledHeading>Project Balance</StyledHeading>
        </div>

        <br />

        <ProjectStats summary={summary} />

        <Box sx={{ padding: "0 1.5vw" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 200,
              }}
            >
              <CircularProgress />
            </Box>
          ) : projectDetails?.length > 0 ? (
            <>
              <BalanceDetails details={projectDetails} />
            </>
          ) : (
            <>
              <Typography
                variant="body1"
                sx={{ textAlign: "center", marginTop: 4 }}
              >
                No data available
              </Typography>
            </>
          )}
        </Box>
      </div>
    </Stack>
  );
}
