import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  styled,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { statusStyleArray } from "../config";
import { formattedAmountWithoutAed } from "../../../utils/CommonFunction";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const getStatusDisplayText = (text) => {
  if (typeof text !== "string") return text;
  const displayText = text
    .replace(/[-_]/g, " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return displayText;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  textTransform: "capitalize",
}));

export default function ProposalDetails({ details }) {
  const navigate = useNavigate();

  const handleChange = (item) => {
    navigate("/ongoing-project", {
      state: { villa: item },
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          overflow: "none !important",
          boxShadow: "none",
          maxHeight: "87vh",
        }}
      >
        <Table
          sx={{
            "& thead": {
              "& th": {
                color: "#949DAA",
                fontWeight: "500",
              },
            },
            "& tbody": {
              "& tr:hover": {
                backgroundColor: "#f7f7f7",
              },
              "& tr": {
                borderBottom: "none",
              },
            },
          }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>S. No.</StyledTableCell>
              <StyledTableCell>Project Name</StyledTableCell>
              <StyledTableCell>Project Type</StyledTableCell>
              <StyledTableCell>Start/ End Date</StyledTableCell>
              <StyledTableCell>Customer</StyledTableCell>
              <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                <strong>Cost (AED)</strong>
              </StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.map((item, idx) => (
              <TableRow key={item.id}>
                <StyledTableCell>{idx + 1}.</StyledTableCell>
                <StyledTableCell>
                  <Box display="flex" alignItems="center">
                    {item.project_image && item.project_image[0].image && (
                      <Box
                        component="img"
                        src={item.project_image[0].image}
                        alt="Project Icon"
                        sx={{
                          width: 45,
                          height: 45,
                          borderRadius: "8px",
                          border: "2px solid white",
                          boxShadow: "0px 4px 2.67px 0px #00000026",
                          marginRight: 1,
                        }}
                      />
                    )}
                    {item?.name}
                  </Box>
                </StyledTableCell>

                <StyledTableCell>{item?.project_type}</StyledTableCell>
                <StyledTableCell>
                  <p
                    style={{
                      color: "#949DAA",
                      marginBottom: "2px",
                      fontWeight: "500",
                    }}
                  >
                    {moment(item?.start_date).format("MMM DD, YYYY")}
                  </p>
                  <p style={{ color: "#949DAA", fontWeight: "500" }}>
                    {moment(item?.end_date).format("MMM DD, YYYY")}
                  </p>
                </StyledTableCell>

                <StyledTableCell>
                  <Box display="flex" alignItems="center">
                    {item?.user_data && item?.user_data?.profile_url && (
                      <Box
                        component="img"
                        src={item?.user_data?.profile_url}
                        alt="Project Icon"
                        sx={{
                          width: 35,
                          height: 35,
                          borderRadius: "50%",
                          border: "2px solid white",
                          boxShadow: "0px 4px 2.67px 0px #00000026",
                          marginRight: 1,
                        }}
                      />
                    )}
                    {item?.user_data?.username}
                  </Box>
                </StyledTableCell>

                <StyledTableCell sx={{ flex: 0.5, textAlign: "right" }}>
                  <strong>{`${formattedAmountWithoutAed(item?.cost)}`}</strong>
                </StyledTableCell>

                <StyledTableCell>
                  <Typography
                    style={{
                      borderRadius: "34px",
                      padding: "4px 8px",
                      fontSize: "14px",
                      minWidth: "7ch",
                      width: "fit-content",
                      backgroundColor:
                        statusStyleArray.find(
                          (style) => style.status === item?.proposal_status
                        )?.backgroundColor || "transparent",
                      color:
                        statusStyleArray.find(
                          (style) => style.status === item?.proposal_status
                        )?.color || "black",
                      border: "none",
                      textAlign: "center",
                    }}
                  >
                    {getStatusDisplayText(item?.proposal_status)}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell>
                  <IconButton
                    size="small"
                    title="View"
                    onClick={() => handleChange(item)}
                  >
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
